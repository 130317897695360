// import "antd/dist/antd.less";
import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Breadcrumb, Button, Spin, Modal, Divider, Select } from "antd";
import { PreviewWidget } from "@/pages/MainPage/frames/Formily/widgets";
import Iframe from "@/pages/MainPage/frames/FormilyMobile/FormilyIframe";
import { useSchemaService } from "@/pages/MainPage/frames/Formily/service";
import { transformToTreeNode } from "@designable/formily-transformer";
import useApi from "../useApi.mjs";
// @ts-ignore
import SoftwareMap from "@/components/SoftwareMap/SoftwareMap.jsx";
import "../styles.less";
import { message, Layout } from "antd";
import { Link, useNavigate } from "react-router-dom";

const PreviewContent = forwardRef(
  ({ current, scaleNum, handlePageChange, setUvObj, ...props }, ref) => {
    const params = new URLSearchParams(location.search.split("?")[1]);
    const uvMode = params.get("uvMode") ? Number(params.get("uvMode")) : 2;
    const schemaService = useSchemaService(uvMode);
    const [shcemaData, setSchemaData] = useState({});
    const [mapData, setMapData] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [historyIdList, setHistoryIdList] = useState([params.get("uvId")]);
    const iframeRef = useRef(null);
    const api = useApi();
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
      handlNavigate(props) {
        handlNavigate(props);
      },
    }));

    const handlNavigate = (obj) => {
      // 将查询字符串分割成键值对
      const queryParams = obj.search?.split("&");
      // 创建一个空对象来存储结果
      const result = {};
      // 遍历键值对，并将其添加到对象中
      queryParams.forEach((param) => {
        const [key, value] = param.split("=");
        result[key] = decodeURIComponent(value);
      });
      navigate(obj);
      fetchData(result.uvId);
    };

    const jsonObject = {};
    for (const [key, value] of params.entries()) {
      jsonObject[key] = value;
    }

    // 添加一个新的状态到历史栈顶
    const pushToHistory = (id) => {
      // if (historyIdList.length > 9) {
      //   setHistoryIdList([...historyIdList.slice(1), id]);
      // } else {
      //   setHistoryIdList([...historyIdList, id]);
      // }
      let urlParams = new URLSearchParams(location.search);
      // 设置新的id值
      urlParams.set('uvId', id);
      const pathName = ['/formilyPreview', '/FormilyFrame'].includes(location.pathname) ? location.pathname : '/formilyPreview'
      handlNavigate({
        pathName,
        search: urlParams.toString()
      });
      // fetchData(id);
    };

    // 返回上一步，从历史栈中弹出最后一个状态
    const goBack = () => {
      if (historyIdList.length > 1) {
        setHistoryIdList(historyIdList.slice(0, -1));
      }
      fetchData(historyIdList[historyIdList.length - 2]);
    };

    useEffect(() => {
      const uvId = params.get("uvId");
      fetchData(uvId);
      // 添加事件监听器
      window.addEventListener("popstate", handlePopState);

      // 返回一个清理函数，用于移除事件监听器
      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }, []);

    const handlePopState = (event) => {
      if (event.state) {
        const params = new URLSearchParams(location.search.split("?")[1]);
        const currentUvId = params.get("uvId");
        fetchData(currentUvId);
      }
    };

    const fetchData = async (id) => {
      if(!id) {
        return
      }
      handlePageChange && handlePageChange(id);
      const data = await schemaService.loadSchema(undefined, id);
      setSchemaData(data || {});
      const fileIdData =
        data?.demoMap && JSON.parse(data?.demoMap)
          ? JSON.parse(data?.demoMap || "")[0]
          : undefined;
      const fileId = fileIdData?.data?.fileId;
      setUvObj({
        uvType: data.uvType,
        uvName: data.uvName,
        fileId,
        uiId: data.uiId,
        uiName: data.uiName
      });
    };

    const handleCtrlClick = async (obj) => {
      const { type, id } = obj;
      if (type == "UE") {
        const res = await api.fetchUeMapJson({ pkid: id });
        const { ueMap, ...data } = res;
        const project = await api.fetchProject({ prjId: data.prjId });
        const newData = {
          ...data,
          bizNodeType: "UE",
          children: JSON.parse(ueMap),
        };
        setMapData({
          root: newData,
          project: project,
        });
        showModal();
      } else if (type == "ALG") {
        api
          .fetchAlgMapJson({
            pkid: id,
          })
          .then((res) => {
            const { algMap, ...data } = res;
            const newData = {
              ...data,
              bizNodeType: "UIALG",
              children: JSON.parse(algMap),
            };
            setMapData({
              root: newData,
            });
            showModal();
          })
          .catch((err) => {
            message.error(err.message);
          });
      }
    };

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const tree = useMemo(() => {
      return shcemaData && shcemaData.uvMap
        ? transformToTreeNode(JSON.parse(shcemaData.uvMap))
        : transformToTreeNode("");
    }, [shcemaData]);
    return (
      <>
        {/* 测试用 */}
        {/* <ShcemaDataPreview shcemaData={shcemaData} uvMode={uvMode} /> */}
        <section className="formily-preview-content">
          {shcemaData.uvType == "MOBILE" ? (
            <div className="formily-preview-content-wrapper">
              <div
                style={{
                  transform: `scale(${scaleNum})`,
                  transformOrigin: scaleNum <= 1 ? "center" : "top center",
                }}
                className="formily-preview-content-mobile"
              >
                <img
                  className="mobile-img"
                  src="//img.alicdn.com/imgextra/i4/O1CN01ehfzMc1QPqY6HONTJ_!!6000000001969-55-tps-459-945.svg"
                  alt=""
                />
                <div className="mobile-content">
                  <div
                    style={{
                      overflow: "overlay",
                      height: "100%",
                      cursor: "auto",
                      userSelect: "text",
                    }}
                  >
                    <Iframe
                      ref={iframeRef}
                      tree={tree}
                      changeData={pushToHistory}
                      handleCtrlClick={handleCtrlClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <PreviewWidget
              {...props}
              uvMode={uvMode}
              handleCtrlClick={handleCtrlClick}
              handlNavigate={handlNavigate}
              tree={tree}
              initParms={jsonObject}
            />
          )}
        </section>
        <Modal
          title="界面能力"
          open={isModalOpen}
          width={"70%"}
          bodyStyle={{ height: "74vh" }}
          centered
          destroyOnClose
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              关闭
            </Button>,
          ]}
        >
          <SoftwareMap mapData={mapData} />
        </Modal>
      </>
    );
  }
);

export default PreviewContent;
