import React, { useEffect, useState, useRef } from 'react';
import { Select } from 'antd';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';

const Dialog = ({ params, value, onChange, onCall, ...props }) => {
    const http = useHTTP();
    const [data, setData] = useState([]);
    const [prjs, setPrjs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        doSearch();
    }, []);

    const doSearch = async () => {
        try {
            setLoading(true);
            const data = await runAsync(
                async () => {
                    return await http.get(`/prjs`, params);
                }, {
                error: { content: (err) => `查询项目失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            const options = [];
            for (const prj of data) {
                options.push({ value: prj.pkid, label: '[' + prj.prjNo + ']' + prj.prjName });
            }

            setPrjs(data);
            setData(options);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Select
            {...props}
            showSearch
            loading={loading}
            options={data}
            value={value}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
            onChange={(e) => {
                if (onChange) {
                    onChange(e);
                }

                // 回调
                const list = prjs.filter(v => v.pkid == e);
                if (onCall && list.length == 1) {
                    onCall(list[0]);
                }
            }}
        />
    );
}

const Pane = ({ form, param, onCall, isMultiple = false, ...props }) => {
    const prjId = param.prjId;
    const sVer = param.sVer;
    const ref = useRef();
    const http = useHTTP();
    const [data, setData] = useState([]);

    useEffect(() => {
        if (prjId) {
            ref.current = { pkid: prjId };
            doSearch(prjId);
        }
    }, [prjId]);

    const doSearch = async (prjId) => {
        try {
            const list = await runAsync(
                async () => {
                    return await http.get(`/prjbaselines`, { prjId, pblStsCode: 'ACTIVE' });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            const options = [];
            for (const v of list) {
                options.push({ value: v.sVer, label: '[' + v.sVer + ']' + v.pblName });
            }
            setData(options);
        } catch (err) {
            console.log(err);
        }
    }

    const onPrj = (value) => {
        ref.current = value;
        onCall(value);
        doSearch(value.pkid);
    }

    return (
        <>
            <Dialog style={{ width: '300px' }} value={prjId} onCall={onPrj} />

            <span>&nbsp;</span>

            <Select
                defaultValue={sVer}
                style={{ width: '300px' }}
                showSearch
                options={data}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
                onChange={v => {
                    if (ref.current) {
                        ref.current.sVer = v;
                        onCall(ref.current);
                    }
                }}
            />
        </>
    );
}

export default Pane;