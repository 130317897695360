import "antd/dist/antd.less";
import React, { useMemo, useEffect, useState, useRef } from "react";
import { Divider, Select, Menu, Layout } from "antd";
import "./styles.less";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import favicon from "/favicon.png";
import { DesktopOutlined } from "@ant-design/icons";
import classNames from "classnames";
import PreviewContent from './components/PreviewContent'

import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import { message } from "antd";

// import ShcemaDataPreview from './schemaDataPreview'

const cssLayoutHeader = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 0,
});
const cssLayoutHeaderLeft = css({
  fontSize: 20,
  color: "#fff",
  padding: "0 15px",
  display: "flex",
  alignItems: "center",
  width: 'calc(40% - 180px)',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
//   flexGrow: "1",
//   flexShrink: "0",
//   flexBasis: 0,
});
const cssLayoutHeaderCenter = css({
  fontSize: 14,
  fontWeight: "bold",
  color: "#fff",
  padding: "0 15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
//   flexGrow: "1",
//   flexShrink: "0",
//   flexBasis: 0,
  gap: 12,
});
const cssLayoutHeaderRight = css({
  color: "#fff",
//   padding: "0 15px",
//   display: "flex",
//   justifyContent: "flex-end",
//   alignItems: "center",
  flexGrow: "1",
  flexShrink: "0",
  flexBasis: 0,
//   gap: 12,
});
const cssLink = css({
  fontWeight: "bold",
  color: "#fff",
  display: "flex",
  alignItems: "center",
});
const cssLogo = css({
  height: 22,
  marginRight: 8,
});
const cssDivider = css({
  borderColor: "#fff",
});

function processArray(arr) {
  let firstUVKey = null;

  function traverse(obj) {
      if (Array.isArray(obj)) {
          for (let item of obj) {
              traverse(item);
          }
      } else if (typeof obj === 'object' && obj !== null) {
          if (obj.type === 'UM' && (!obj.children || obj.children.length == 0)) {
              obj.disabled = true;
          } else if (obj.type === 'UV' && firstUVKey === null) {
              firstUVKey = obj.key;
          }

          for (let key in obj) {
              if (obj.hasOwnProperty(key)) {
                  traverse(obj[key]);
              }
          }
      }
  }

  traverse(arr);

  return firstUVKey;
}

const Header = ({ current, uvObj, menuData, setScaleNum, handleMenuChange }) => {
  // const [current, setCurrent] = useState(typeList[0].items[0].key);
  const [frameKey, setFrameKey] = useState(menuData[0]?.key)
  useEffect(() => {
    setFrameKey(menuData[0]?.key)
  }, [menuData])
  const handleChange = (val) => {
    setScaleNum(val);
  };

  const handleMenuClick = e => {
    handleMenuChange(e.key)
  };

  const handleFrameKeyChange = (key) => {
    if (key == frameKey) return;
    const items = menuData.find(item => item.key == key).children;
    const currentKey = items[0].key;
    setFrameKey(key)
    handleMenuChange(currentKey)
  }

  // const fileId = shcemaData?.uvMap && JSON.parse(shcemaData?.uvMap || '') ? JSON.parse(shcemaData.uvMap).fileId : undefined;


  return (
    <Layout.Header css={cssLayoutHeader}>
      <div css={cssLayoutHeaderLeft}>
        <Link css={cssLink} reloadDocument to="/" title={"进入工作台"}>
          <img css={cssLogo} alt="logo" src={favicon} />
          软件逻辑地图
        </Link>
        <Divider type="vertical" css={cssDivider} />
        <span style={{ fontSize: 14, fontWeight: "bold" }}>在线预览</span>
      </div>

      <div css={cssLayoutHeaderCenter}>
        {menuData && menuData.length > 0 && <Select
          value={frameKey}
          dropdownMatchSelectWidth={false}
          style={{ width: 120 }}
          options={menuData.map((item) => ({
            value: item.key,
            label: item.label,
          }))}
          onChange={handleFrameKeyChange}
        ></Select>}
        <span title={uvObj.uvName} className="header_uvname">
          {uvObj.uvName}
        </span>

        {uvObj.uvType == "MOBILE" && (
          <Select
            defaultValue={1}
            bordered={false}
            style={{ color: "#fff", width: 58 }}
            showArrow={false}
            size="small"
            onChange={handleChange}
            options={[
              {
                value: 0.35,
                label: "35%",
              },
              {
                value: 0.5,
                label: "50%",
              },
              {
                value: 0.8,
                label: "80%",
              },
              {
                value: 1,
                label: "100%",
              },
              {
                value: 1.2,
                label: "120%",
              },
              {
                value: 1.5,
                label: "150%",
              },
              {
                value: 2,
                label: "200%",
              },
            ]}
          />
        )}
      </div>

      <div css={cssLayoutHeaderRight}>
        <Menu
          style={{ justifySelf: "end", flexWrap: "wrap" }}
          theme="dark"
          onClick={handleMenuClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={menuData.find((item) => item.key == frameKey)?.children}
        />
      </div>
    </Layout.Header>
  );
};

const FormilyFrame = (props) => {
  const params = new URLSearchParams(location.search.split("?")[1]);

  const [uvObj, setUvObj] = useState({});
  const [current, setCurrent] = useState(null);
  // const history = useHistory();
  const http = useHTTP();
  const [menuData, setMenuData] = useState([])

  const [scaleNum, setScaleNum] = useState(1);
  // const [firstUvKey, setFirstUvKey] = useState(null)

  // const iframeRef = useRef(null);
  const previewRef = useRef(null)

  useEffect(() => {
    doQuery()
  }, [params.get('prjId'), params.get('sVer')])

  const doQuery = async () => {
    const prjId = params.get('prjId');
    const sVer = params.get('sVer') || 1;
    if (!prjId) return message.warning('未找到项目ID')
    const data = await runAsync(
        async () => {
            return await http.get("/archuas/loadUvtree", {
              prjId,
              sVer
            })
        },
        {
            error: {content: (err) => `查询菜单失败: ${err.message}`},
            loading: {show: false},
            success: {show: false},
        }
    );

    if (data) {
        const firstUVKey = processArray(data);
        // setCurrent(firstUVKey)
        if (!firstUVKey) {
          message.warning("当前项目没有可用的视图")
        } else {
          handleMenuChange(firstUVKey)
        }
        
        setMenuData(data);
    }
}

  const handleMenuChange = (id) => {
    // const iframeWindow = iframeRef.contentWindow
    setCurrent(id)
    if (previewRef.current) {
      let urlParams = new URLSearchParams(location.search);
     
      // 设置新的id值
      urlParams.set('uvId', id);
      const pathName = ['/formilyPreview', '/FormilyFrame'].includes(location.pathname) ? location.pathname : '/formilyPreview'
      previewRef.current.handlNavigate({
        pathName,
        search: urlParams.toString()
      }); // 调用子组件的方法
    }
  }

  const handlePageChange = (id) => {
    setCurrent(id)
  }

  return (
    <div className="formilyFrame" style={{ width: "100vw", height: "100vh", background: "#f0f2f5" }}>
      <Header
        current={current}
        setScaleNum={setScaleNum}
        menuData={menuData}
        uvObj={uvObj}
        handleMenuChange={handleMenuChange}
      />
      {/* <iframe
        ref={iframeRef}
        style={{ border: 0, width: "100%", height: "100%", display:'block' }}
        src="/FormilyPreview"
        onError={() => setHasError(true)}
      >
        当前页面不支持iframe内嵌
      </iframe> */}
      <PreviewContent current={current} ref={previewRef} scaleNum={scaleNum} handlePageChange={handlePageChange} setUvObj={setUvObj} />
    </div>
  );
};

export default FormilyFrame;
